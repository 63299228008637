<template>
  <div v-loading="loadingTwo" element-loading-background="rgba(0, 0, 0, 0.8)">
    <section class="cards">
      <div class="cards__wrapper cards__row">
        <!-- <div class="card"> -->
        <div
          class="card card--two"
          v-for="card in userDetailsTwo.cards"
          :key="card.seqno"
          @click="setCardDetails(card)"
        >
          <div class="card__top">
            <svg
              v-if="card.cardtype === 'SBP_MASTERCARD'"
              width="33"
              height="20"
              viewBox="0 0 33 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.994 9.99994C19.994 15.524 15.519 19.9999 9.99698 19.9999C4.47495 19.9999 0 15.523 0 9.99994C0 4.47692 4.47604 0 9.99698 0C15.5179 0 19.994 4.47692 19.994 9.99994Z"
                fill="#E2574C"
              />
              <path
                d="M22.003 0C19.761 0 17.697 0.747013 16.029 1.99404L16.037 1.99503C16.365 2.312 16.727 2.53508 17.006 2.89998L14.926 2.93299C14.6 3.26202 14.303 3.62001 14.023 3.99204H17.6909C17.9699 4.32699 18.2279 4.61808 18.462 4.98802H13.358C13.171 5.31003 12.998 5.64104 12.847 5.98499H19.043C19.205 6.32795 19.35 6.587 19.4731 6.95004H12.48C12.369 7.29508 12.2741 7.64802 12.202 8.00799H19.766C19.84 8.35402 19.897 8.67395 19.936 9.00002H12.052C12.019 9.32905 12.002 9.66302 12.002 10.0001H19.9929C19.9929 10.3541 19.9679 10.682 19.932 11.0001H12.052C12.086 11.3391 12.136 11.6721 12.202 12.0001H19.754C19.676 12.3241 19.5859 12.6501 19.473 12.9881H12.457C12.563 13.3301 12.692 13.6621 12.833 13.9861H19.043C18.871 14.3501 18.676 14.6411 18.4611 14.982H13.3401C13.5422 15.332 13.7651 15.666 14.0071 15.986L17.691 16.041C17.377 16.4179 16.9741 16.645 16.607 16.975C16.6269 16.991 16.02 16.973 14.8249 16.954C16.643 18.8301 19.184 20 22.0029 20C27.5259 20 32.0028 15.523 32.0028 10.0001C32.0028 4.47714 27.5269 0 22.003 0Z"
                fill="#F4B459"
              />
            </svg>
          </div>
          <div class="card__num">{{ card.pan | formatCard }}</div>
          <div class="card__bottom">
            <div class="card__name">
              <span>Card Holder</span
              >{{ userDetailsTwo.accountname | capitalize }}
            </div>
            <div class="card__exp">
              <span>Expires</span>{{ card.exp | capitalize }}
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </section>

    <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import PinModal from "@/widgets/PinModal.vue";

export default {
  components: {
    "app-pin-modal": PinModal
  },
  computed: mapState(["userDetailsTwo", "user"]),
  data() {
    return {
      showPinModal: false,
      loadingTwo: false,
      accountNumber: ""
    };
  },
  methods: {
    setCardDetails(card) {
      this.$store.dispatch("setCardDetails", card);
      this.showPinModal = true;
    },
    fixChromeMask() {
      // Modal mask fix for chrome
      this.$refs.otpInput.$el.children[0].setAttribute(
        "autocomplete",
        "new-password"
      );
      const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
      inputs.forEach(i => i.setAttribute("autocomplete", "new-password"));
    },
    setUserPin(value) {
      this.showPinModal = false;

      let payload = {
        userId: this.user.profileInfo.SMID,
        pin: value
      };

      this.loadingTwo = true;

      api
        .handlePinValidation(payload)
        .then(response => {
          this.loadingTwo = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let feedback = response.Data;
            if (feedback.isvalid) {
              this.$router.push("/dashboard/cards/card-actions");
            }
          } else {
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000
          });
        });
    }
  },
  watch: {
    showPinModal: function(newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.fixChromeMask();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
